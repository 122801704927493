<template>
    <div class="payResult">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="payResult_box">
            <img src="@/assets/course/paySuccess.png" alt="">
            <img src="@/assets/course/payError.png" alt="" v-if="type==2">
            <div class="payResult_box_explain">
                <p>恭喜您，支付成功</p>
                <!-- <p>恭喜您，成功购买课程</p> -->
            </div>
            <div class="payResult_box_explain" v-if="type==2">
                <p>支付失败</p>
            </div>
            <!-- <div class="payResult_box_btn" >
                <el-button @click="goOrderList()">查看订单</el-button>
                <el-button type="primary" @click="goLearnCenter()">进入学习中心</el-button>
            </div> -->
            <div class="payResult_box_btn" v-if="type==2">
                <el-button @click="goService()">在线客服</el-button>
                <el-button type="primary" @click="goHome()">返回首页</el-button>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
export default {
    name:'payResult',
    components: {
        t_header,
        t_footer
    },
    data(){
        return{
            type:1,
            isUserInfo:false,
        }
    },
    mounted(){
        this.type=this.$route.query.type
    },
    methods:{
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        // 去订单列表
        goOrderList(){
            this.$router.push({
                path:'/myself/myOrder',
                query:{

                }
            })
        },
        // 去客服
        goService(){
            var url='https://tb.53kf.com/code/client/28a6552fc10b901b687a66116675d2e07/1'
            // let routeData = this.$router.resolve({ path: url, query: {} }); 
            window.open(url, '_blank');
        },
        goHome(){
            this.$router.push({
                path:'/',
                query:{}
            })
        },
        goLearnCenter(){
            this.$router.push({
                path:'/myself/myClass'
            })
        }
    }
}
</script>

<style>

</style>